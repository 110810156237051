import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { convertColor, getRotatedMarkerCoordinate } from "../../utils";
import getIcon from "../../utils/getIcon";
import "./styles.css";

class PushLayer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      bgColor: "#000000"
    };
  }

  changeOpacity = (hex, opacity) => {
    this.setState({ bgColor: convertColor(hex, opacity) });
  };

  handleOnMouseEnter = hex => this.changeOpacity(hex, 1);
  handleOnMouseLeave = hex => this.changeOpacity(hex, 0.5);

  render() {
    const {
      link,
      played,
      duration,
      switchToVideo,
      dx,
      dy,
      cameraAngle,
      is360Active
    } = this.props;

    const { bgColor } = this.state;
    let _left, _bottom;

    if (is360Active === true) {
      _left = getRotatedMarkerCoordinate(
        (60 * Math.PI) / 360,
        link.x_position_on_video_v5,
        (dx * Math.PI) / 360
      );
      _bottom = getRotatedMarkerCoordinate(
        (44 * Math.PI) / 360,
        link.y_position_on_video_v5,
        (-dy * Math.PI) / 360
      );
    } else {
      _left = getRotatedMarkerCoordinate(
        (60 * Math.PI) / 360,
        link.x_position_on_video_v5,
        (1 * Math.PI) / 360
      );
      _bottom = getRotatedMarkerCoordinate(
        (44 * Math.PI) / 360,
        link.y_position_on_video_v5,
        (1 * Math.PI) / 360
      );
    }

    const videoMark = {
      position: "absolute",
      bottom: `${_bottom}%`,
      left: `${_left}%`
    };

    const visibleCondition = () => {
      if (
        window.location.hostname == "player-v2.pisteview.com" &&
        link.timeframe_link_entry_point <= played * (duration - 1.2) &&
        link.timeframe_link_exit_point > played * (duration - 1.2)
      ) {
        return true;
      }
    };

    return (
      <div
        className={`player__pushoverlay
					${link.icon_size === "near-field" && ".player__pushoverlay--near"}
					${visibleCondition() ? "visible" : "hidden"}`}
        style={videoMark}
        onMouseEnter={() => this.handleOnMouseEnter("#cccccc")}
        onMouseLeave={() => this.handleOnMouseLeave("#ffffff")}
      >
        <text
          className="label push__label"
          fill="#fff"
          strokeWidth="0"
          strokeLinejoin="null"
          strokeLinecap="null"
          fontSize={link.icon_size === "near-field" ? "40" : "25"}
          id="svg_9"
          textAnchor="middle"
          x={link.svg_text_x}
          y={link.svg_text_y}
        >
          {link.label}
        </text>
      </div>
    );
  }
}

PushLayer.propTypes = {
  link: PropTypes.object.isRequired,
  played: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
  switchToVideo: PropTypes.func.isRequired,
  dx: PropTypes.number,
  dy: PropTypes.number,
  cameraAngle: PropTypes.number
};

PushLayer.defaultProps = {
  dx: 0,
  dy: 0,
  cameraAngle: 100
};

export default PushLayer;
