import React, { Component } from 'react';
import { MapContainer, TileLayer, Marker, Tooltip } from 'react-leaflet'; // Import Tooltip here
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import MapBadge from '../../components/MapBadge';
import './styles.css';

class CustomMap extends Component {
    state = {
        showMap: false,
    };

    static defaultProps = {
        zoom: 15,
    };

    componentDidMount() {
        this.setState({ showMap: true });
    }

    onLoadVideo = (id) => {
        this.props.switchToVideo(id);
        this.props.history.push('/');
    };

    render() {
        const { locations, currentVideoId } = this.props;
        const center = [this.props.currentVideoLat, this.props.currentVideoLng];

        if (!this.props.currentVideoLat && !this.props.currentVideoLng) {
            return null;
        }

        const defaultIcon = new L.Icon({
            iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
            iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
            shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
            tooltipAnchor: [16, -28],
            shadowSize: [41, 41]
        });

        return (
            <div className="map">
                {this.state.showMap && (
                    <MapContainer center={center} zoom={this.props.zoom} style={{ height: "100%", width: "100%" }}>
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        />
                        {locations.map((location) => (
                            <Marker
                                key={location.id}
                                position={[location.location_latitude, location.location_longitude]}
                                icon={defaultIcon}
                                eventHandlers={{
                                    click: () => this.onLoadVideo(location.video.id),
                                }}
                            >
                                <Tooltip permanent={false}> {/* Tooltip will show on hover */}
                                    {location.label}
                                </Tooltip>
                            </Marker>
                        ))}
                    </MapContainer>
                )}
            </div>
        );
    }
}

export default CustomMap;