import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { convertColor, getRotatedMarkerCoordinate } from "../../utils";
import getIcon from "../../utils/getIcon";
import "./styles.css";

class VideoMarker extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      bgColor: convertColor(this.props.link.video_info.video_type.icon_color, 0.5),
    };
  }

  changeOpacity = (hex, opacity) => {
    this.setState({ bgColor: convertColor(hex, opacity) });
  };

  handleOnMouseEnter = (hex) => this.changeOpacity(hex, 1);
  handleOnMouseLeave = (hex) => this.changeOpacity(hex, 0.5);

  render() {
    const { link, played, playedSeconds, duration, switchToVideo, dx, dy, cameraAngle, is360Active } = this.props;

    const { bgColor } = this.state;
    let _left, _bottom;

    if (is360Active === true) {
      _left = getRotatedMarkerCoordinate((60 * Math.PI) / 360, link.x_position_on_video_v5, (dx * Math.PI) / 360);
      _bottom = getRotatedMarkerCoordinate((44 * Math.PI) / 360, link.y_position_on_video_v5, (-dy * Math.PI) / 360);
    } else {
      _left = getRotatedMarkerCoordinate((60 * Math.PI) / 360, link.x_position_on_video_v5, (1 * Math.PI) / 360);
      _bottom = getRotatedMarkerCoordinate((44 * Math.PI) / 360, link.y_position_on_video_v5, (1 * Math.PI) / 360);
    }
    const videoMark = {
      position: "absolute",
      bottom: `${_bottom}%`,
      left: `${_left}%`,
    };
    /*
    if (_left < 4 || _left > 97) {
      videoMark.display = "none";
    }
    if (_bottom < 25 || _bottom > 97) {
      videoMark.display = "none";
    }
*/
    const visibleCondition = () => {
      if (link.timeframe_link_entry_point <= playedSeconds && link.timeframe_link_exit_point >= playedSeconds) {
        if (!is360Active && link.category === "video") {
          return true;
        }
        if (is360Active && link.category !== "video") {
          return true;
        }
      }
      /*
          if (
          link.timeframe_link_entry_point <= played * (duration - 1.2) &&
          link.timeframe_link_exit_point > played * (duration - 1.2)
        ) {
          if (!is360Active && link.category === "video") {
            return true;
          }
          if (is360Active && link.category !== "video") {
            return true;
          }
        }
        */
    };
    return (
      <div
        className={`pv-icon-holder
					${link.icon_size === "near-field" && "player__overlay--near"}
					${visibleCondition() ? "visible" : "hidden"}`}
        style={videoMark}
        onClick={() => switchToVideo(link.links_to_video, link.timeframe_video_entry_point)}
        onMouseEnter={() => this.handleOnMouseEnter(link.video_info.video_type.icon_color)}
        onMouseLeave={() => this.handleOnMouseLeave(link.video_info.video_type.icon_color)}
      >
        {link.video_info.video_type.image_icon ? (
          <div className="pv-icon" style={{ color: bgColor }}>
            <div className="pv-icon__symbol pv-icon__symbol--v2" style={{ backgroundImage: `url(${getIcon(link.video_info.video_type.icon)})`, backgroundColor: bgColor }}></div>
            <div className="pv-icon__label">{link.label}</div>
          </div>
        ) : (
          <div className="pv-icon" style={{ color: bgColor }}>
            <div className="pv-icon__symbol" style={{ backgroundColor: bgColor }}>
              {link.label}
            </div>
            <div className="pv-icon__label">{link.long_label}</div>
          </div>
        )}
      </div>
    );
  }
}

VideoMarker.propTypes = {
  link: PropTypes.object.isRequired,
  played: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
  switchToVideo: PropTypes.func.isRequired,
  dx: PropTypes.number,
  dy: PropTypes.number,
  cameraAngle: PropTypes.number,
};

VideoMarker.defaultProps = {
  dx: 0,
  dy: 0,
  cameraAngle: 100,
};

export default VideoMarker;
