import React from "react";
import PushLayer from "../../components/PushLayer";

const PushLayerList = ({
  infos = [],
  played,
  duration,
  switchToVideo,
  dx,
  dy,
  cameraAngle,
  is360Active
}) => {
  return infos.map(info => {
    return (
      <PushLayer
        key={info.id}
        link={info}
        played={played}
        duration={duration}
        switchToVideo={switchToVideo}
        dx={dx}
        dy={dy}
        cameraAngle={cameraAngle}
        is360Active={is360Active}
      />
    );
  });
};

export default PushLayerList;
